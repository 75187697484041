<template>
  <div class="card-panel">
    <div class="card-panel--left">
      <div class="card-section">
        <div class="card-header">最近服务项目</div>
        <div class="card-table">
          <div class="card-item" v-for="(item, index) in recentServices" :key="index">
            <span>{{ item.rq }}</span>
            <span>{{ item.sj }}</span>
            <span style="width: 20%;">{{ item.fwxm }}</span>
            <span>{{ item.fwgk }}</span>
            <span>{{ item.kx }}</span>
            <span>{{ item.dh }}</span>
            <span style="width: 10%;">{{ item.fwyg }}</span>
          </div>
        </div>
      </div>
      <div class="card-section">
        <div class="card-header">
          <span>卡系服务寄存清单</span>
          <Select style="width:240px">
            <Option v-for="item in types" :key="item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
          <span>状态</span>
          <Select style="width:120px">
            <Option value="待服务">待服务</Option>
            <Option value="服务超时">服务超时</Option>
            <Option value="服务预警">服务预警</Option>
          </Select>
          <span class="more-btn" @click="goToMore">更多内容 >></span>
        </div>
        <div class="card-table">
          <Table :columns="dataColumns" :data="dataList"></Table>
          <div>
            <span class="records">共 {{ dataTotal }} 条记录</span>
          </div>
          <div class="page-nations-wrapper">
            <div class="page-nations">
              <Page
                :total="dataTotal"
                :current="dataCurrentPage"
                :page-size="query.size"
                @on-change="changeDataPage"
              ></Page>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-panel--right">
      <div class="card-section">
        <div style="height: 450px;" ref="chart-pie"></div>
      </div>
      <div class="card-section">
        <div style="height: 450px;" ref="chart-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import cardService from '@/services/cardService';
import echarts from 'echarts';

export default {
	data() {
		return {
			types: [],
			status: [],
			recentServices: [],
			dataColumns: [
				{ type: 'index', width: 60 },
				{ title: '顾客姓名', key: 'gkmc', align: 'center', width: 120 },
				{
					title: '状态',
					key: 'zt',
					align: 'center',
					width: 100,
					render: (h, params) => {
						return h('span', {
							style: {
								display: 'inline-block',
								height: '14px',
								width: '14px',
								borderRadius: '14px',
								backgroundColor:
									params.row.zt === 1 ? 'gray' : params.row.zt === 2 ? 'red' : '#fdad00',
								verticalAlign: 'middle',
							},
						});
					},
				},
				{ title: '卡系', key: 'kx', align: 'center' },
				{ title: '项目', key: 'xmmc', align: 'center' },
				{ title: '次数', key: 'cs', align: 'center', width: 100 },
				{ title: '电话', key: 'dh', align: 'center' },
			],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			query: {
				size: 15,
				page: 1,
			},
		};
	},
	mounted() {
		cardService.projectTypes().then((data) => {
			this.types = data;
		});
		cardService.cardServiceAlarm().then((data) => {
			echarts.init(this.$refs['chart-pie']).setOption({
				tooltip: {
					trigger: 'item',
				},
				title: {
					text: '卡系服务统计图',
					bottom: 20,
					left: 'center',
				},
				legend: {
					top: '5%',
					left: 'center',
				},
				color: ['#f94d3d', '#f7b942', '#a1a1a1'],
				series: [
					{
						name: '卡系服务统计图',
						type: 'pie',
						radius: ['30%', '60%'],
						data: [
							{
								value: data['超时服务'],
								name: '超时服务',
								label: { fontSize: 16 },
							},
							{
								value: data['预警服务'],
								name: '预警服务',
								label: { fontSize: 16 },
							},
							{
								value: data['待服务'],
								name: '待服务',
								label: { fontSize: 16 },
							},
						],
					},
				],
			});
		});
		cardService.cardServiceStatistics().then((data) => {
			echarts.init(this.$refs['chart-line']).setOption({
				title: {
					text: '卡系月度服务统计',
					bottom: 10,
					left: 'center',
				},
				legend: {
					bottom: 50,
					left: 'center',
				},
				grid: {
					top: 20,
					left: '3%',
					right: '4%',
					bottom: 90,
					containLabel: true,
				},
				color: ['#a6dde3', '#a9c6fb'],
				tooltip: {
					trigger: 'axis',
				},
				xAxis: [
					{
						type: 'category',
						data: data.x_list,
					},
				],
				yAxis: [
					{
						type: 'value',
						axisLine: { show: false },
						axisTick: { show: false },
						splitLine: {
							lineStyle: {
								type: 'dashed',
							},
						},
					},
				],
				series: data.y_list.map((item) => ({
					smooth: true,
					name: item.kxlx,
					type: 'line',
					stack: 'Total',
					areaStyle: {},
					emphasis: {
						focus: 'series',
					},
					data: item.data,
				})),
			});
		});
		this.getRecentServices();
		this.getCardServiceList();
	},
	methods: {
		getRecentServices() {
			cardService.recentServices().then((data) => {
				this.recentServices = data;
			});
		},
		getCardServiceList() {
			cardService.cardServiceList(this.query).then((data) => {
				this.dataList = data.list;
				this.dataTotal = data.row_size;
				this.dataCurrentPage = data.cur_page;
			});
		},
		changeDataPage(page) {
			this.query.page = page;
			this.getCardServiceList();
		},
		goToMore() {
			this.$router.push({ name: 'cardManagement' });
		},
	},
};
</script>

<style lang="less" scoped>
.card-panel {
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 16px;
  &--left {
    flex: 1;
  }
  &--right {
    width: 500px;
  }
}
.card-section {
  box-shadow: #f4f4fe 0 2px 6px;
  padding: 0 15px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .more-btn {
    margin-left: auto;
    color: #aaa;
    font-size: 14px;
    cursor: pointer;
  }
  .card-header {
    font-size: 16px;
    height: 38px;
    line-height: 38px;
    border-bottom: 1px solid #f4f4fe;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .card-item {
    display: flex;
    margin: 10px 0;
    span {
      flex: auto;
    }
  }
}
</style>